import { put, select } from "redux-saga/effects";
import dayjs from "dayjs";

import { fetchTripDetails as fetchDetails } from "../../../api/v0/shop/fetchTripDetails";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import {
  FlightShopStep,
  TripCategory,
  type PriceFreezeOfferDataResponse,
  type TripDetails,
} from "redmond";
import { getDepartureDate, getTripCategory } from "../../search/reducer";
import { getPriceFreezeOfferData } from "../../../api/v0/price-freeze/getPriceFreezeOfferData";
import { flightShopProgressSelector } from "../reducer";

export function* fetchTripDetailsSaga({
  tripId,
  priceFreezeOffersEnabled,
  filterFareDetail,
}: actions.IFetchTripDetails) {
  try {
    const tripDetails: TripDetails = yield fetchDetails(tripId);

    if (filterFareDetail) {
      tripDetails.fareDetails = tripDetails.fareDetails.filter((fare) =>
        filterFareDetail(fare)
      );
    }

    // For experiment "c1-price-freeze-new-flights-list-entry"
    if (priceFreezeOffersEnabled) {
      // There are a few business rules we want to follow when showing the price freeze offer CTA
      // - Show CTA on eligible one-way trips
      // - Show the CTA on eligible round trips if the user is selecting the return flight
      const tripCategory: TripCategory = yield select(getTripCategory);
      const flightShopStep: FlightShopStep = yield select(
        flightShopProgressSelector
      );
      const isRoundTrip = tripCategory === TripCategory.ROUND_TRIP;
      const isSelectingReturnFlight =
        flightShopStep === FlightShopStep.ChooseReturn;

      const departureDate: Date | null = yield select(getDepartureDate);
      const tripId = tripDetails.id;

      // Filtering for refundable fares and airlines exclusions are done in Tysons
      for (const fare of tripDetails.fareDetails) {
        if (isRoundTrip && !isSelectingReturnFlight) {
          break;
        }

        const { offerData: priceFreezeOffers }: PriceFreezeOfferDataResponse =
          yield getPriceFreezeOfferData({
            departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
            tripId,
            fareId: fare.id,
          });

        if (priceFreezeOffers && priceFreezeOffers.length > 0) {
          const defaultPriceFreezeOffer =
            priceFreezeOffers[Math.floor(priceFreezeOffers.length / 2)]; // Select the middle offer

          fare.defaultPriceFreezeOffer = defaultPriceFreezeOffer;
        }
      }
    }

    yield put(actions.setTripDetails(tripDetails));
  } catch (e) {
    Logger.debug(e);
  }
}
