import { isRapidRebookRenameEnabledSelector } from "./../../ancillary/reducer/selectors/disruptionProtection";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import * as H from "history";
import { actions } from "../actions";
import { IStoreState } from "../../../reducers/types";
import { FlightShopV2 } from "./component";
import {
  isFlightShopLoadingTripSummaries,
  flightsByFlightShopTypeSelector,
  flightShopProgressSelector,
  tripDetailsByIdSelector,
  tripSummariesErrorSelector,
  tripSummariesErrorCodeSelector,
  getViewedFlightListPropertiesForV2,
  getSortedAndFilteredFlights,
  selectedTripSelector,
  hasSetNonFareclassFiltersSelectorV2,
  getSelectedOutgoingSlicePropertiesV2,
  getSelectedReturnSlicePropertiesV2,
  flightShopTypeSelector,
  refreshPredictionSelector,
  isOutgoingMultiTicketSelector,
  isReturnMultiTicketSelector,
  predictionSelector,
  getPriceFreezeOfferCheapestTripTripId,
  tripDetailsSelector,
  airportsSelector,
  getPriceFreezeOffer,
  getPriceFreezeOfferCheapestTripFareId,
  getPriceFreezeCheapestFrozenPrice,
  getPriceFreezeOfferDuration,
  getPriceFreezeOfferRewards,
  getPriceFreezeOfferCap,
  getPriceFreezeOfferFiat,
  priceDropProtectionCandidateIdSelector,
  hasPriceFreezeOnOutboundSelector,
  getOffersByTripId,
  getTravelOfferProperties,
  isInDisruptionProtectionRebookSelector,
  isPartiallyRebookingSelector,
  isPartiallyRebookingNotificationPropsSelector,
  isRefundableFaresActiveInFlightShopSelector,
  hasSelectedRefundableFareSelector,
  hasActiveRefundableFareInFlightShopSelector,
  refundableFaresPropertiesSelector,
  showNoFtcOnlyInRefundableFaresSelector,
  sortOptionSelector,
  hasSetMaxPriceFilterSelectorV2,
  openFlightShopCalendarDesktopSelector,
  getFlightShopEntryPoint,
  cfarDiscountPropertiesSelector,
  flightSlicesSelector,
  getAirEntryProperties,
  invertedStopsFilterFlightsSelector,
  FlightShopStep,
  isSpiritOrFrontierAirlinesSelectedSelector,
  minFlightPriceSelectorV2,
} from "../reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../rewards/reducer";
import {
  getActiveFiltersCount,
  getApplyUserFlightPreferences,
  getDepartureDate,
  getDestination,
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getHasSetPolicyFilter,
  getHasUserSetFlightPreferences,
  getMaxPriceFilter,
  getOrigin,
  getReturnDate,
  getStopsOption,
  getTripCategory,
  getUserFlightPreferences,
  getUserFlightPreferencesCallState,
  getUserFlightPreferencesNotAvailable,
  initialFilterOptions,
} from "../../search/reducer";
import { isPriceFreezeDurationEnabledSelector } from "../../freeze/reducer";
import {
  isCustomizePageMarketplaceEnabledSelector,
  isDisruptionProtectionEnabledSelector,
  isCfarEnabledSelector,
  isChfarEnabledSelector,
  originalSliceFlightSummaryPropsSelector,
  viewedRebookingFlightListPropertiesSelector,
  rebookSummaryCallStateSelector,
  fetchSingleFlightItineraryCallStateSelector,
  isSelectingReturnFlightSelector,
  disruptedFlightDpExerciseFactsPropertiesSelector,
  rebookingViewedSlicePropertiesSelector,
  rebookingConfirmedSlicePropertiesSelector,
  rebookDepartureDateTimeSelector,
  cfarOffersSelector,
  batchCfarOffersCallStateSelector,
  hasUpdatedCfarOffersForReturnFlightsSelector,
  isAirOfferRedesignEnabledSelector,
  isAirRefundableFareCopyEnabledSelector,
} from "../../ancillary/reducer";
import {
  FareclassOptionFilter,
  AirlineCode,
  IFlightNumberFilter,
  ITimeRange,
  SliceStopCountFilter,
  TripCategory,
  FetchCfarOfferRequestV2,
  FlightShopType,
  CfarId,
  RefundableFaresProperties,
  FlightSortOption,
  FiatPrice,
} from "redmond";
import {
  fetchTripSummariesForPredictionV2,
  setChosenOutgoingSlice,
  setChosenReturnSlice,
  setFlightShopProgress,
  setOpenFlightShopCalendarDesktop,
  setHasPriceFreezeOnOutbound,
  resetFetchAncillaryOfferState,
  setFlightShopType,
  setSelectedCfarId,
  setHasSelectedRefundableFare,
  updateRefundableFaresProperties,
  setSortOption,
  setPolicyLimit,
  IFlightShopOverwriteQueryParams,
  setFlightShopRecommendedBasedOnPreferences,
  setSelectedMarketingAirlineCodes,
  setSelectedOperatingAirlineCodes,
  listPaymentMethods,
} from "../actions/actions";
import { actions as searchActions } from "../../search/actions";
import { initializeOfferDataAndCustomOffer } from "../../freeze/actions/actions";
import {
  setOriginalFlightItineraryId,
  fetchRebookSummary,
  fetchSingleFlightItinerary,
  fetchFlightDisruptionsByItineraryId,
  setDisruptedFlightItinerarySliceAndSegmentIds,
  resetCfarOffers,
  batchFetchCfarOffers,
} from "../../ancillary/actions/actions";
import { fetchRewardsAccounts } from "../../rewards/actions/actions";
import {
  IFlightBookOverwriteQueryParams,
  populateFlightBookQueryParams,
} from "../../book/actions/actions";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../../travel-wallet/actions/actions";
import { getTravelWalletCredit } from "../../travel-wallet/reducer";
import {
  fetchUserFlightPreferences,
  setApplyUserFlightPreferences,
  setDepartureDate,
  setDurationFilter,
  setReturnDate,
  setUserPreferencesNotAvailable,
} from "../../search/actions/actions";
import { Flights } from "@b2bportal/air-shopping-api";
import { isThebesHackerFaresV2Cap1ExperimentAvailable } from "../reducer/selectors/experiments";
import { Dispatch } from "@reduxjs/toolkit";
import { FareDetails } from "redmond/build";
import { getFlightShopRecommendedBasedOnPreferences } from "../../book/reducer";
import { fetchPotentialCrossSellOffers } from "../../cross-sell/actions/actions";
import { getPotentialCrossSellOffers } from "../../cross-sell/reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  const priceFreezeOfferCheapestTripTripDetails = tripDetailsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );

  const priceFreezeOfferCheapestTripAirports = airportsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );

  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";

  return {
    tripSummariesLoading: isFlightShopLoadingTripSummaries(state),
    hasFlightsError: tripSummariesErrorSelector(state) || false,
    flightsErrorCode: tripSummariesErrorCodeSelector(state) || null,
    flights: flightsByFlightShopTypeSelector(state) as Flights | null,
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    fareClassFilter: getFareclassOptionFilter(state),
    flightShopProgress: flightShopProgressSelector(state),
    isRoundTrip: getTripCategory(state) === TripCategory.ROUND_TRIP,
    origin: getOrigin(state),
    destination: getDestination(state),
    tripDetailsById: tripDetailsByIdSelector(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    flightList: getSortedAndFilteredFlights(state),
    invertedStopsFilterFlightList: invertedStopsFilterFlightsSelector(state),
    slicesMap: flightSlicesSelector(state),
    viewedForecastProperties: getViewedFlightListPropertiesForV2(state),
    selectedTrip: selectedTripSelector(state),
    maxFlightPriceFilter: getMaxPriceFilter(state),
    hasAppliedFareClassFilter: getHasSetFareClassFilter(state),
    hasAppliedNonFareclassFilter: hasSetNonFareclassFiltersSelectorV2(state),
    selectedOutgoingSliceProperties:
      getSelectedOutgoingSlicePropertiesV2(state),
    selectedReturnSliceProperties: getSelectedReturnSlicePropertiesV2(state),
    flightShopType: flightShopTypeSelector(state),
    refreshPrediction: refreshPredictionSelector(state),
    isMultiTicket:
      isOutgoingMultiTicketSelector(state) ||
      isReturnMultiTicketSelector(state),
    prediction: predictionSelector(state),
    priceFreezeOfferCheapestTripAirports,
    priceFreezeOfferCheapestTripTripDetails,
    priceFreezeOffer: getPriceFreezeOffer(state),
    priceFreezeOfferCheapestTripFareId:
      getPriceFreezeOfferCheapestTripFareId(state),
    priceFreezeRewards: getPriceFreezeOfferRewards(state),
    priceFreezeDuration: getPriceFreezeOfferDuration(state),
    cheapestFrozenPrice: getPriceFreezeCheapestFrozenPrice(state),
    priceFreezeFiat: getPriceFreezeOfferFiat(state),
    priceFreezeCap: getPriceFreezeOfferCap(state),
    priceDropProtectionCandidateId:
      priceDropProtectionCandidateIdSelector(state),
    hasPriceFreezeOnOutbound: hasPriceFreezeOnOutboundSelector(state),
    offersByTripId: getOffersByTripId(state),
    travelOfferProperties: getTravelOfferProperties(state),
    isPriceFreezeDurationEnabled: isPriceFreezeDurationEnabledSelector(state),
    isCustomizePageMarketplaceEnabled:
      isCustomizePageMarketplaceEnabledSelector(state),
    isDisruptionProtectionEnabled: isDisruptionProtectionEnabledSelector(state),
    isCfarEnabled: isCfarEnabledSelector(state),
    isChfarEnabled: isChfarEnabledSelector(state),
    isInDisruptionProtectionRebook:
      isInDisruptionProtectionRebookSelector(state),
    isPartiallyRebooking: isPartiallyRebookingSelector(state),
    originalSliceFlightSummaryProps:
      originalSliceFlightSummaryPropsSelector(state),
    isPartiallyRebookingNotificationProps:
      isPartiallyRebookingNotificationPropsSelector(state),
    credit: getTravelWalletCredit(state),
    viewedRebookingFlightListProperties:
      viewedRebookingFlightListPropertiesSelector(state),
    rebookSummaryCallState: rebookSummaryCallStateSelector(state),
    fetchSingleFlightItineraryCallState:
      fetchSingleFlightItineraryCallStateSelector(state),
    isSelectingReturnFlightForRebook: isSelectingReturnFlightSelector(state),
    isRefundableFaresActive: isRefundableFaresActiveInFlightShopSelector(state),
    cfarOffers: cfarOffersSelector(state),
    batchCfarOffersCallState: batchCfarOffersCallStateSelector(state),
    hasSelectedRefundableFare: hasSelectedRefundableFareSelector(state),
    refundableFaresProperties: refundableFaresPropertiesSelector(state),
    hasActiveRefundableFare: hasActiveRefundableFareInFlightShopSelector(state),
    hasUpdatedCfarOffersForReturnFlights:
      hasUpdatedCfarOffersForReturnFlightsSelector(state),
    showNoFtcOnlyInRefundableFares:
      showNoFtcOnlyInRefundableFaresSelector(state),
    disruptedFlightDpExerciseFactsProperties:
      disruptedFlightDpExerciseFactsPropertiesSelector(state),
    rebookingViewedSliceProperties:
      rebookingViewedSlicePropertiesSelector(state),
    rebookingConfirmedSliceProperties:
      rebookingConfirmedSlicePropertiesSelector(state),
    rebookDepartureDateTime: rebookDepartureDateTimeSelector(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    sortOption: sortOptionSelector(state),
    hasSetMaxPriceFilter: hasSetMaxPriceFilterSelectorV2(state),
    tripCategory: getTripCategory(state),
    openCalendarDesktop: openFlightShopCalendarDesktopSelector(state),
    flightShopEntryPoint: getFlightShopEntryPoint(state),
    isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
    cfarDiscountProperties: cfarDiscountPropertiesSelector(state),
    airEntryProperties: getAirEntryProperties(state),
    isThebesHackerFaresV2Cap1ExperimentAvailable:
      isThebesHackerFaresV2Cap1ExperimentAvailable(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    airports: airportsSelector(state, tripId),
    stopsOption: getStopsOption(state),
    isInPolicyFilter: getHasSetPolicyFilter(state),
    isAirRefundableFareCopyEnabled:
      isAirRefundableFareCopyEnabledSelector(state),
    userFlightPreferencesCallState: getUserFlightPreferencesCallState(state),
    userFlightPreferences: getUserFlightPreferences(state),
    userHasSetFlightPreferences: getHasUserSetFlightPreferences(state),
    userFlightPreferencesNotAvailable:
      getUserFlightPreferencesNotAvailable(state),
    shouldApplyUserFlightPreferences: getApplyUserFlightPreferences(state),
    flightShopRecommendedBasedOnPreferences:
      getFlightShopRecommendedBasedOnPreferences(state),
    activeFiltersCount: getActiveFiltersCount(state),
    isSpiritOrFrontierAirlinesSelected:
      isSpiritOrFrontierAirlinesSelectedSelector(state),
    minFlightPrice: minFlightPriceSelectorV2(state),
    potentialCrossSellOffers: getPotentialCrossSellOffers(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTripSummaries: (
    history: H.History,
    isMobile?: boolean,
    includeLocationLabels?: boolean,
    includeStopsFilter?: boolean,
    trackNonStopFilter?: boolean
  ) =>
    dispatch(
      actions.fetchTripSummariesV3(
        history,
        isMobile,
        includeLocationLabels,
        includeStopsFilter,
        trackNonStopFilter
      )
    ),
  stopFetchTripSummaries: () => dispatch(actions.stopFetchTripSummariesV3()),
  fetchTripDetails: (args: {
    tripId: string;
    // For experiment "c1-price-freeze-new-flights-list-entry"
    priceFreezeOffersEnabled: boolean;
    filterFareDetail?: (fare: FareDetails) => boolean;
  }) => dispatch(actions.fetchTripDetails(args)),
  setFlightShopProgress: (progress: FlightShopStep) =>
    dispatch(setFlightShopProgress(progress)),
  setChosenReturnSlice: ({
    tripId,
    returnFareId,
    returnSliceId,
    returnFareRating,
  }: {
    tripId: string;
    returnFareId: string;
    returnSliceId: string;
    returnFareRating?: number;
  }) =>
    dispatch(
      setChosenReturnSlice({
        returnSliceId,
        returnFareId,
        returnFareRating,
        tripId,
      })
    ),
  setChosenOutgoingSlice: ({
    outgoingSliceId,
    outgoingFareId,
    tripId,
    outgoingFareRating,
    resetReturnIds,
  }: {
    outgoingSliceId: string;
    outgoingFareId: string;
    tripId: string;
    outgoingFareRating?: number;
    resetReturnIds?: boolean;
  }) =>
    dispatch(
      actions.setChosenOutgoingSlice({
        outgoingSliceId,
        outgoingFareId,
        tripId,
        outgoingFareRating,
        resetReturnIds,
      })
    ),
  setOpenFlightShopCalendarDesktop: (openFlightShopCalendarDesktop: boolean) =>
    dispatch(setOpenFlightShopCalendarDesktop(openFlightShopCalendarDesktop)),
  setAirlineFilter: (airlineFilter: AirlineCode[]) =>
    dispatch(searchActions.setAirlineFilter(airlineFilter)),
  setStopsOption: (
    stopsOption: SliceStopCountFilter,
    location?: "search" | "list"
  ) => dispatch(searchActions.setStopsOption(stopsOption, location)),
  setFlightNumberFilter: (flightNumberFilter: IFlightNumberFilter[]) =>
    dispatch(searchActions.setFlightNumberFilter(flightNumberFilter)),
  setPolicyFilter: (policyFilter: boolean) =>
    dispatch(searchActions.setPolicyFilter(policyFilter)),
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) =>
    dispatch(
      searchActions.setOutboundArrivalTimeRange(outboundArrivalTimeRange)
    ),
  setOutboundDepartureTimeRange: (outboundDepartureTimeRange: ITimeRange) =>
    dispatch(
      searchActions.setOutboundDepartureTimeRange(outboundDepartureTimeRange)
    ),
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) =>
    dispatch(
      searchActions.setReturnDepartureTimeRange(returnDepartureTimeRange)
    ),
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) =>
    dispatch(searchActions.setReturnArrivalTimeRange(returnArrivalTimeRange)),
  setMaxPriceFilter: (maxPriceFilter: number) =>
    dispatch(searchActions.setMaxPriceFilter(maxPriceFilter)),
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter,
    location?: "search" | "list"
  ) =>
    dispatch(
      searchActions.setFareclassOptionFilter(fareclassOptionFilter, location)
    ),
  setAirportFilter: (airportFilter: string[]) =>
    dispatch(searchActions.setAirportFilter(airportFilter)),
  populateFlightBookQueryParams: (args: {
    history: H.History;
    pathname?: string;
    preserveQuery?: boolean;
    newQueryParams?: IFlightBookOverwriteQueryParams;
  }) => dispatch(populateFlightBookQueryParams(args)),
  fetchTripSummariesForPrediction: (history: H.History, isMobile?: boolean) =>
    dispatch(fetchTripSummariesForPredictionV2(history, isMobile)),
  rerunPrediction: () => dispatch(actions.setRerunPrediction()),
  setOpenFlightShopCalendarMobile: (openFlightShopCalendarMobile: boolean) =>
    dispatch(
      actions.setOpenFlightShopCalendarMobile(openFlightShopCalendarMobile)
    ),
  setSelectedFlightIndex: (selectedFlightIndex: number) =>
    dispatch(actions.setSelectedFlightIndex(selectedFlightIndex)),
  populateFlightShopQueryParams: (args: {
    history: H.History;
    prevPath?: string;
    useHistoryPush?: boolean;
    forceQueryUpdate?: boolean;
    newQueryParams?: IFlightShopOverwriteQueryParams;
  }) => dispatch(actions.populateFlightShopQueryParams(args)),
  setHasPriceFreezeOnOutbound: (hasPriceFreezeOnOutbound: boolean) =>
    dispatch(setHasPriceFreezeOnOutbound(hasPriceFreezeOnOutbound)),
  initializeOfferDataAndCustomOffer: (args: {
    departureDate: string;
    tripId: string;
    fareId: string;
    history: H.History;
  }) => dispatch(initializeOfferDataAndCustomOffer(args)),
  resetFetchAncillaryOfferState: (args?: {
    preserveCfarId?: boolean;
    preserveDisruptionProtectionId?: boolean;
  }) => dispatch(resetFetchAncillaryOfferState(args)),
  fetchTravelWalletDetails: () => dispatch(fetchTravelWalletDetails()),
  fetchRewardsAccounts: () => dispatch(fetchRewardsAccounts()),
  setOriginalFlightItineraryId: (itineraryId: string) =>
    dispatch(setOriginalFlightItineraryId(itineraryId)),
  fetchRebookSummary: (args: {
    origin: string;
    destination: string;
    itineraryId: string;
    history: H.History;
    isMobile?: boolean;
  }) => dispatch(fetchRebookSummary(args)),
  fetchSingleFlightItinerary: (itineraryId: string) =>
    dispatch(fetchSingleFlightItinerary(itineraryId)),
  fetchFlightDisruptionsByItineraryId: (itineraryId: string) =>
    dispatch(fetchFlightDisruptionsByItineraryId(itineraryId)),
  resetCfarOffers: () => dispatch(resetCfarOffers()),
  batchFetchCfarOffers: (
    requests: FetchCfarOfferRequestV2[],
    variant?: "return-flights" | "default"
  ) => dispatch(batchFetchCfarOffers(requests, variant)),
  setFlightShopType: (flightShopType: FlightShopType) =>
    dispatch(setFlightShopType(flightShopType)),
  setDisruptedFlightItinerarySliceAndSegmentIds: (
    sliceIndex: number,
    segmentIndex: number
  ) =>
    dispatch(
      setDisruptedFlightItinerarySliceAndSegmentIds(sliceIndex, segmentIndex)
    ),
  setSelectedCfarId: (cfarId: CfarId | null) =>
    dispatch(setSelectedCfarId(cfarId)),
  setHasSelectedRefundableFare: (hasSelectedRefundableFare: boolean) =>
    dispatch(setHasSelectedRefundableFare(hasSelectedRefundableFare)),
  updateRefundableFaresProperties: (properties: RefundableFaresProperties) =>
    dispatch(updateRefundableFaresProperties(properties)),
  setSortOption: (sortOption: FlightSortOption) =>
    dispatch(setSortOption(sortOption)),
  setDepartureDate: (date: Date | null) => dispatch(setDepartureDate(date)),
  setReturnDate: (date: Date | null) => dispatch(setReturnDate(date)),
  setTripCategory: (
    tripCategory: TripCategory,
    updateTripCategoryOnly?: boolean
  ) =>
    dispatch(
      searchActions.setTripCategory(tripCategory, updateTripCategoryOnly)
    ),
  fetchTravelWalletCreditHistory: () =>
    dispatch(fetchTravelWalletCreditHistory()),
  setDurationFilter: (duration: number) =>
    dispatch(setDurationFilter(duration)),
  setPolicyLimit: (policyLimit: FiatPrice | null | undefined) =>
    dispatch(setPolicyLimit(policyLimit)),
  fetchUserFlightPreferences: () => dispatch(fetchUserFlightPreferences()),
  resetAll: (resetFilters: boolean) => {
    if (resetFilters) {
      dispatch(searchActions.setStopsOption(initialFilterOptions.stopsOption));
      dispatch(
        searchActions.setAirlineFilter(initialFilterOptions.airlineFilter)
      );
      dispatch(
        searchActions.setFareclassOptionFilter({
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        })
      );
      dispatch(
        searchActions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter)
      );
      dispatch(
        searchActions.setAirportFilter(initialFilterOptions.airportFilter)
      );
      dispatch(
        searchActions.setPolicyFilter(initialFilterOptions.policyFilter)
      );
      dispatch(
        searchActions.setFlightNumberFilter(
          initialFilterOptions.flightNumberFilter
        )
      );
      dispatch(
        searchActions.setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        searchActions.setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        searchActions.setReturnDepartureTimeRange(
          initialFilterOptions.returnDepartureTimeRange
        )
      );
      dispatch(
        searchActions.setReturnArrivalTimeRange(
          initialFilterOptions.returnArrivalTimeRange
        )
      );
      dispatch(
        searchActions.setDurationFilter(initialFilterOptions.durationFilter)
      );
      dispatch(searchActions.setApplyUserFlightPreferences(false));
      dispatch(searchActions.setUserPreferencesNotAvailable(false));
    }
    setChosenOutgoingSlice({
      tripId: "",
      outgoingSliceId: "",
      outgoingFareId: "",
      outgoingFareRating: undefined,
      resetReturnIds: true,
    });
  },
  setApplyUserFlightPreferences: (applyPreferences: boolean) =>
    dispatch(setApplyUserFlightPreferences(applyPreferences)),
  setUserPreferencesNotAvailable: (userPreferencesNotAvailable: boolean) =>
    dispatch(setUserPreferencesNotAvailable(userPreferencesNotAvailable)),
  setFlightShopRecommendedBasedOnPreferences: (
    recommendedBasedOnPreferences: boolean
  ) =>
    dispatch(
      setFlightShopRecommendedBasedOnPreferences(recommendedBasedOnPreferences)
    ),
  setSelectedMarketingAirlineCodes: (marketingAirlineCodes: string[]) =>
    dispatch(setSelectedMarketingAirlineCodes(marketingAirlineCodes)),
  setSelectedOperatingAirlineCodes: (operatingAirlineCodes: string[]) =>
    dispatch(setSelectedOperatingAirlineCodes(operatingAirlineCodes)),
  fetchPotentialCrossSellOffers: () =>
    dispatch(fetchPotentialCrossSellOffers()),
  listPaymentMethods: () => dispatch(listPaymentMethods()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShop = withRouter(connector(FlightShopV2));
