// TODO: BASE_PATH_HOME might be better removed in future; it probably belongs to FlightModuleProps in Redmond
export const BASE_PATH_HOME = "/";
export const PATH_HOME = "/flights/";
export const PATH_HOME_SEARCH = PATH_HOME + "search/";
export const PATH_SHOP = PATH_HOME + "shop/";
export const PATH_BOOK = PATH_HOME + "book/";
export const PATH_FREEZE = PATH_HOME + "freeze/";
export const PATH_BOOK_CONFIRMATION = PATH_BOOK + "confirm/";
export const PATH_BOOK_WITH_PF_CONFIRMATION =
  PATH_BOOK + "pricefreeze/confirm/";
export const PATH_BOOK_WITH_VI_CONFIRMATION =
  PATH_BOOK + "virtualInterline/confirm/";
export const PATH_FREEZE_CONFIRMATION = PATH_FREEZE + "confirm/";
export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_PRICE_DROP_PROTECTION = `${PATH_TERMS}price-drop-protection/`;
export const PATH_MISSED_CONNECTION_GUARANTEE = `${PATH_TERMS}missed-connection-guarantee/`;
export const PATH_PRICE_PREDICTION_PRICE_WATCH = `${PATH_TERMS}price-prediction-price-watch/`;
export const PATH_BEST_PRICE_GUARANTEE = `${PATH_TERMS}best-price-guarantee/`;
export const PATH_PRICE_FREEZE = `${PATH_TERMS}frozen-prices/`;
export const PATH_CFAR = `${PATH_TERMS}cancel-for-any-reason/`;
// TODO need chfar to have its own path. will be done in https://hopper-jira.atlassian.net/browse/FLEX-2965
export const PATH_CHFAR = `${PATH_TERMS}cancel-for-any-reason/`;
export const PATH_DISRUPTION_PROTECTION = `${PATH_TERMS}rapid-rebook/`;
export const PATH_PRICE_FREEZE_OVERVIEW = `${PATH_FREEZE}overview/`;
export const PATH_PRICE_FREEZE_CUSTOMIZE = `${PATH_FREEZE}customize/`;
export const PATH_ANCILLARY = PATH_HOME + "ancillary/";
export const PATH_DISRUPTION_OFFER = PATH_HOME + "disruption-offer/";
export const PATH_DISRUPTION_PROTECTION_REBOOK = PATH_ANCILLARY + "disruption/";
export const PATH_DISRUPTION_PROTECTION_REBOOK_CONFIRMATION =
  PATH_DISRUPTION_PROTECTION_REBOOK + "confirm/";
export const PATH_DISRUPTION_PROTECTION_VIRTUAL_INTERLINE_REBOOK_CONFIRMATION =
  PATH_DISRUPTION_PROTECTION_REBOOK + "virtualInterline/confirm/";
export const PATH_TRIPS = `${BASE_PATH_HOME}trips/`;
export const PATH_DISRUPTION_PROTECTION_OVERVIEW = PATH_TRIPS + "disruption/";
export const PATH_DISRUPTION_PROTECTION_REFUND =
  PATH_DISRUPTION_PROTECTION_OVERVIEW + "refund/";
export const PATH_TRAVEL_SALE = `/travelsale/`;

export const FAQ_URL =
  "https://www.capitalone.com/help-center/capital-one-travel/";

export const HOTEL_PATH = "/hotels";

export const HOTEL_PATH_SHOP = "/hotels/shop/";
export const HOTEL_PATH_AVAILABILITY = "/hotels/availability/";

export const PATH_CUSTOMER_PROFILE = `/profile/`;

export const PATH_PACKAGES = "/packages";
// Corporate
export const PATH_PRICE_MATCH_GUARANTEE = `${PATH_TERMS}price-match-guarantee/`;
