import * as React from "react";
import { Box } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import "./styles.scss";
import { FareDetails } from "redmond";

interface IFlightFreezeEntryCTAContentProps {
  fare?: FareDetails;
}
export const FlightFreezeEntryCTAContent: React.FC<
  IFlightFreezeEntryCTAContentProps
> = ({ fare }) => {
  // We'll also control rendering of the CTA based on the presence of the defaultPriceFreezeOffer
  if (!fare?.defaultPriceFreezeOffer) {
    return null;
  }

  const { currencySymbol, value } = fare.defaultPriceFreezeOffer?.price.fiat;
  const offerPrice = `${currencySymbol}${value}`;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="flight-freeze-entry-cta-content-wrapper"
    >
      <Box position="relative">
        <Icon
          className="price-freeze-icon"
          name={IconName.B2bPriceFreezeNoCircle}
        />
        Freeze price for <strong>{offerPrice}</strong>
      </Box>
    </Box>
  );
};
